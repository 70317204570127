import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const BlackNonfictionCinema = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Black Nonfiction Cinema" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>Black Nonfiction Cinema</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Aug. 2, 2021) </p>
        </div>
        <div className="articleBody">
          <p>
            My study of history has led me to the position that every African
            child born over the next millennium should be entitled to two items
            at birth: a gun and a camera.
          </p>
          <p>
            So yeah here go some recommendations, I'm by no means a cinosaur
            (LMAO) but for the totally uninitiated you might find something
            interesting here. I'm writing about these movies the best way I know
            how: without having seen a single one of em in the past 6 months
            lmao I know I'm a real Richard Brody, but naw it's actually a cool
            exercise to see what stuck with me.{" "}
          </p>
          <p>
            <span className="highlight">
              <i>Midnight In Paris</i> (2019)
            </span>{" "}
            dir. Roni Moore & James Blagden
          </p>
          <p>
            This jawn was Thurl w/ a capital T, prolly the first one outta all
            of these I'll rewatch. If Les Blank was still living he woulda saw
            this jawn and wished he made it fr (but he couldn't have). It's just
            specific as shit w/ a legendary premise: senior prom in Flint,
            Michigan in 2012. I ain't know prom got that heavy out there I was
            geeked all throughout lmao. I remember thinking like damn this jawn
            really distilled this particular culture so well but didn't feel
            like it was trapping none of the people in a diorama which is hard
            not to do in <i>any</i> documentary let alone one about a captive
            population in a notorious city-without-water. The way they used
            music was great, the characters was dumb lovable, it really was just
            a pure hit of joy and nostalgia no rerock. It reminded me that Black
            high-schooler humor is unparalleled, I was in the theater ctfu. It
            also reminded me that I went on prom with one of the best people I
            know s/o my yungbol Joshalyn! So yeah long story short this jawn a
            hit and lucky for you it's streaming for free{" "}
            <a href="https://www.youtube.com/watch?v=pxu-BbHPyRU">
              right here on YouTube
            </a>{" "}
            now that's love, word to Roni and the Blag Man.
          </p>
          <p>
            <span className="highlight">
              <i>Talking About Trees</i> (2019)
            </span>{" "}
            dir. Suhaib Gasmelbari
          </p>
          <p>
            Ya zol lmaoooo. Naw this one right here, it mighta been created in a
            lab just for me. I could share a special anecdote from my childhood
            that would make you agree, but this still the Internet so get off my
            dick lol. But the fact that olhead made this whole thing in secret
            is really an inspiration. As much as I try not to be super joe
            cinephile bul, I couldn't imagine having to hide my love of talkies
            lol. The four main characters in this embody the original meaning of
            the word cool (as revealed to me by the Spacebug herself). Imagine
            having the courage to try to screen <i>Django Unchained</i> under
            nutass Omar al-Bashir lmao I'd like to think that's the type
            gumption I would have in that scenario. Plus I just never suspected
            that I would ever see a camel at a movie theater before LMAO that's
            one of my favorite moments. And the part where the one jidu was
            tryna track down his movie from back in the day in broken Russian...
            I didn't realize we had olheads like these, really brought a tear to
            my eye.
          </p>
          <p>
            <span className="highlight">
              <i>Time</i> (2020)
            </span>{" "}
            dir. Garrett Bradley
          </p>
          <p>
            The way the main lady in this jawn was moving...just made me regret
            ever saying the B-word in my whole natural-born life man smh. Talk
            about some Divine Femininity I'm bouta just go full Hotep ctfuuuu. I
            can't lie I sometimes wince at the thought of using any archival
            footage at all cuz it seems like such a tall task like damn you want
            me to look through allat??? But the execution here is impeccable and
            it was literally and figuratively a labor of love I just gotta
            respect the craft 🤝💯. And this jawn was just like the most
            elegeant samurai slice at mass incarceration where the victim just
            quietly split into two w/o realizing he even died — and while I'm
            here I'll just repeat the statistic that always bears repetition cuz
            it's so insane: Americans make up 5% of the global population but
            25% of the global prison population that's so fucking gnarly imma
            just break that down for the mathematically challenged... If you
            took all the people in the world, 1 in every 20 of them would be
            American. Now if you took all the *prisoners* in the world, 1 in
            every 4 would be American that's so fucking crazy man beam me up.
            Anyway back to the movie, these poor kids man, got a tough ass mom
            but I know that shit had to be hard, but the one yungbol prolly fuck
            around be Obama 3.0 (AOC is Obama 2.0 and Obama is Bill Clinton 2.0
            and so on all the way back to the Fallen Angel decalcify ya pineal I
            couldn't resist this tangent ctfuuuu) in a crazy ass plot twist I
            sure hope not though. Shorty snapped on the new <i>Naomi Osaka</i>{" "}
            jawn too I might got a lil camera crush keep it a stack but word to
            all my Vespucci dogs, iykyk type shit.
          </p>
          <p>
            <span className="highlight">
              <i>Black Mother</i> (2018)
            </span>{" "}
            dir. Khalik Allah
          </p>
          <p>
            This one really activated the most of my senses though, just dumb
            sensory like you turned on the Byakugan and whatever smelling jutsu
            Kiba got lmao. What I love about it most in retrospect is how I
            remember feeling dumb hazy and vibed out like I was really in
            Jamaica the whole time, but then the jawn just got dumb literal at
            the end to snap me back to reality lmfaoooo touché akbar talib. This
            is prolly the most challenging watch outta all of these but very
            rewarding, you gotta just submit forreal. Eventually felt like I was
            meditating and it just got way more lit soon as I turned that
            corner. That's fitting too cuz word on the street is main man got
            Kiarostami in the lineage LMAO but just blend the Wu in there and
            it's off the charts.
          </p>
          <p>
            <span className="highlight">
              <i>Hoop Dreams</i> (1994)
            </span>{" "}
            dir. Steven James
          </p>
          <p>
            This is the only movie here that isn't directed by a Black person
            but...it's <i>Hoop Dreams</i>. I consider this one foundational to
            my love of documentaries, I've watched it a half dozen times
            probably, which amounts to 18 hours of my life cuz this jawn is a
            whopping 3 hours long. Well worth it though. It operates on so many
            levels: class, race, crime, family, friendship, education, all
            wrapped up inside a basketball movie. Imma watch this one second. I
            don't follow sports as closely as I used to when I was a younger
            man, so I'm sure this will be just the fix I've been needing.
          </p>
          <p>
            After that millennium is up, that should be the entitlement of all
            children, and more.
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2021</p>
        </div>
      </div>
    </div>
  )
}
export default BlackNonfictionCinema
